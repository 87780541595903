export const PROJECT_NAME = 'داشبورد - برگ منو';
export const BASE_URL =
  process.env.REACT_APP_BASE_URL || 'https://api.bargfood.test';
export const WEBSITE_URL =
  process.env.REACT_APP_WEBSITE_URL || 'https://panel.bargfood.test';
export const api_url = BASE_URL + '/api/v1/';
export const api_url_admin = BASE_URL + '/api/v1/dashboard/admin/';
export const api_url_admin_dashboard = BASE_URL + '/api/v1/dashboard/';
export const IMG_URL = '';
export const MAP_API_KEY = 'AIzaSyBbfnvObzwcs6OLXOmSaG9CtIhwjmBwLTQ';
export const export_url = BASE_URL + '/storage/';
export const example = BASE_URL + '/';
export const FOOTER_WEBSITE_URL =
  process.env.REACT_FOOTER_WEBSITE_URL || 'www.barg.menu';

export const VAPID_KEY = '';

export const LAT = 35.7001287;
export const LNG = 51.3339285;

// export const API_KEY = 'AIzaSyC-94TkEzZAFhV4XLq9q-EmWsx_z1_ZARo';
// export const AUTH_DOMAIN = 'foodyman-703bd.firebaseapp.com';
// export const PROJECT_ID = 'foodyman-703bd';
// export const STORAGE_BUCKET = 'foodyman-703bd.appspot.com';
// export const MESSAGING_SENDER_ID = '723986947199';
// export const APP_ID = '1:723986947199:web:5b3b23e1e9f97083f5334a';
// export const MEASUREMENT_ID = 'G-09DRT9D4L0';

export const RECAPTCHASITEKEY = process.env.REACT_APP_RECAPTCHA_KEY || '';

export const DEMO_SELLER = 334; // seller_id
export const DEMO_SELLER_UUID = '3566bdf6-3a09-4488-8269-70a19f871bd0'; // seller_id
export const DEMO_SHOP = 599; // seller_id
export const DEMO_DELIVERYMAN = 375; // deliveryman_id
export const DEMO_MANEGER = 114; // deliveryman_id
export const DEMO_MODERATOR = 297; // deliveryman_id
export const DEMO_ADMIN = 107; // deliveryman_id

export const unlimitedQuantity = 999_999_999;
export const unlimitedMaxQty = 1_000;

export const SUPPORTED_FORMATS = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/svg+xml',
  'image/svg',
];
