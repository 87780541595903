import React from 'react';
import { useSelector } from 'react-redux';

export default function Footer() {
  const { settings } = useSelector((state) => state.globalSettings);

  return (
    <footer className='footer'>
      <span className='font-weight-semibold'>{settings.title}</span>
      <span>
        <a href={`tel:${settings.phone}`}>{settings.phone}</a>
      </span>
    </footer>
  );
}
