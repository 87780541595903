import React, { useEffect, useState } from 'react';
import { data } from '../../configs/menu-config';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  notification,
  Row,
  Typography,
} from 'antd';
import authService from '../../services/auth';
import { useDispatch, useSelector } from 'react-redux';
import { setUserData } from '../../redux/slices/auth';
import {
  fetchRestSettings,
  fetchSettings,
} from '../../redux/slices/globalSettings';
import { useTranslation } from 'react-i18next';
import { PROJECT_NAME } from '../../configs/app-global';
import { setMenu } from 'redux/slices/menu';
const { Title } = Typography;

const Login = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { settings } = useSelector((state) => state.globalSettings);

  const handleLogin = (values) => {
    const body = {
      password: values.password,
    };

    if (values.email.match(/^(\+?[0-9]*)$/)) {
      body.phone = values.email;
    } else {
      body.email = values.email;
    }

    setLoading(true);
    authService
      .login(body)
      .then((res) => {
        const user = {
          fullName: res.data.user.firstname + ' ' + res.data.user.lastname,
          role: res.data.user.role,
          urls: data[res.data.user.role],
          img: res.data.user.img,
          token: res.data.access_token,
          email: res.data.user.email,
          id: res.data.user.id,
          shop_id: res.data.user?.shop?.id,
        };

        switch (user.role) {
          case 'user':
            notification.error({
              message: t('ERROR_101'),
            });
            return;

          case 'waiter':
            dispatch(
              setMenu({
                icon: 'user',
                id: 'orders-board',
                name: 'my.orders',
                url: 'waiter/orders-board',
              })
            );
            break;

          case 'admin':
          case 'manager':
            dispatch(
              setMenu({
                name: 'dashboard',
                icon: 'dashboard',
                url: 'dashboard',
                id: 'dashboard_01',
              })
            );
            break;

          case 'seller':
          case 'moderator':
            dispatch(
              setMenu({
                name: 'orders',
                icon: 'fiShoppingCart',
                url: 'seller/orders',
                id: 'orders',
              })
            );
            break;

          default:
            break;
        }

        localStorage.setItem('token', res.data.access_token);
        dispatch(setUserData(user));
        if (user.role === 'admin') {
          dispatch(fetchSettings());
        } else {
          dispatch(fetchRestSettings());
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    dispatch(fetchRestSettings());
  }, [dispatch]);

  return (
    <div className='login-container'>
      <div className='container d-flex flex-column justify-content-center h-100'>
        <Row justify='center'>
          <Col>
            <Card className='card'>
              <div className='my-4 pl-4 pr-4 w-100'>
                <div className='app-brand text-center'>
                  <Title className='brand-logo'>
                    {settings.title || PROJECT_NAME}
                  </Title>
                </div>
                <Row justify='center'>
                  <Col>
                    <Form
                      name='login-form'
                      layout='vertical'
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      form={form}
                      onFinish={handleLogin}
                      style={{ width: '420px' }}
                    >
                      <Form.Item
                        name='email'
                        label={t('email.or.phone')}
                        requiredMark={false}
                        rules={[
                          {
                            required: true,
                            message: false,
                          },
                        ]}
                      >
                        <Input
                          prefix={
                            <MailOutlined className='site-form-item-icon' />
                          }
                          placeholder={t('email.or.phone')}
                        />
                      </Form.Item>
                      <Form.Item
                        name='password'
                        label={t('password')}
                        rules={[
                          {
                            required: true,
                            message: false,
                          },
                        ]}
                      >
                        <Input.Password
                          prefix={
                            <LockOutlined className='site-form-item-icon' />
                          }
                          placeholder={t('password')}
                        />
                      </Form.Item>
                      <Form.Item className='login-input mt-4'>
                        <Button
                          type='primary'
                          htmlType='submit'
                          className='login-form-button'
                          loading={loading}
                        >
                          {t('login')}
                        </Button>
                      </Form.Item>
                    </Form>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default Login;
