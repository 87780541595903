import request from './request';

const pushNotification = {
  getUnread: () => request.get('dashboard/notifications'),
  getAll: () =>
    request.get('dashboard/notifications', { params: { column: 'read_at' } }),
  getById: (id, params) =>
    request.get(`dashboard/notifications/${id}`, { params }),
  read: (id) => request.post(`dashboard/notifications/${id}/read-at`),
  readAll: () => request.post(`dashboard/notifications/read-all`),
};

export default pushNotification;
