import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { shallowEqual } from 'react-redux';

export const PathLogout = ({ children }) => {
  const { user } = useSelector((state) => state.auth, shallowEqual);

  if (user) {
    return <Navigate to='/' replace />;
  }

  return children;
};
