import { Button, Card, Drawer, List } from 'antd';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { addMenu } from '../redux/slices/menu';
import { useNavigate } from 'react-router-dom';
import { digitsEnToFa } from '@persian-tools/persian-tools';
import jMoment from 'moment-jalaali';
import pushNotification from 'services/pushNotification';
import { toast } from 'react-toastify';
import { CheckOutlined } from '@ant-design/icons';
import { GrNotes } from 'react-icons/gr';
import { TbBellRinging } from 'react-icons/tb';

export default function NotificationDrawer({
  handleClose,
  visible,
  list,
  clear,
  refetch,
  drawerExtra,
}) {
  const { user } = useSelector((state) => state.auth, shallowEqual);
  const { direction } = useSelector((state) => state.theme.theme, shallowEqual);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const markAsRead = async (id) => {
    try {
      await pushNotification.read(id);
      refetch();
    } catch (error) {
      toast.error(t('error.occurred.try.again'));
    }
  };

  const goToShow = (id) => {
    let url = '';
    switch (user.role) {
      case 'admin':
        url = `order/details/${id}`;
        break;
      case 'manager':
        url = `order/details/${id}`;
        break;
      case 'seller':
        url = `seller/order/details/${id}`;
        break;
      case 'moderator':
        url = `seller/order/details/${id}`;
        break;
      case 'deliveryman':
        url = `deliveryman/order/details/${id}`;
        break;

      default:
        break;
    }
    dispatch(
      addMenu({
        url,
        id: 'order_details',
        name: t('order.details'),
      })
    );
    navigate(`/${url}`);
  };

  const handleClick = (item) => {
    switch (item.type) {
      case 'new_in_table':
        item?.read_at === undefined && markAsRead(item.id);
        break;

      case 'new_order':
        item?.read_at === undefined && markAsRead(item.id);
        goToShow(item.data.order_id);
        break;

      default:
        break;
    }
  };

  return (
    <Drawer
      title={t('notifications')}
      placement={direction === 'rtl' ? 'right' : 'left'}
      closable={true}
      onClose={handleClose}
      open={visible}
      key={'left'}
      extra={drawerExtra}
    >
      <List
        size='small'
        itemLayout='horizontal'
        dataSource={list}
        renderItem={(item) => {
          switch (item.type) {
            case 'new_in_table':
              return (
                <Card
                  title={
                    <div className='d-flex align-items-center gap-2'>
                      <TbBellRinging />
                      <span className='font-size-base'>
                        {t('waiter.was.called')}
                      </span>
                    </div>
                  }
                  headStyle={{
                    backgroundColor: item.read_at ? '#f0f2f5' : '#dcfce7',
                  }}
                  hoverable={!item.read_at}
                  onClick={() => handleClick(item)}
                  extra={
                    !item.read_at && (
                      <Button
                        type='text'
                        shape='circle'
                        icon={<CheckOutlined />}
                        size='small'
                      />
                    )
                  }
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <span>
                      {t('table.number')}: {digitsEnToFa(item?.data?.table_id)}
                    </span>
                    <span>{jMoment(item?.created_at).fromNow()}</span>
                  </div>
                </Card>
              );

            case 'new_order':
              return (
                <Card
                  title={
                    <div className='d-flex align-items-center gap-2'>
                      <GrNotes />
                      <span className='font-size-base'>
                        {`${t('new.order')} (${digitsEnToFa(
                          item?.data?.order_id || 0
                        )})`}
                      </span>
                    </div>
                  }
                  headStyle={{
                    backgroundColor: item.read_at ? '#f0f2f5' : '#dcfce7',
                  }}
                  hoverable={!item.read_at}
                  onClick={() => handleClick(item)}
                  extra={
                    !item.read_at && (
                      <Button
                        type='text'
                        shape='circle'
                        icon={<CheckOutlined />}
                        size='small'
                        onClick={(e) => {
                          e.stopPropagation();
                          markAsRead(item.id);
                        }}
                      />
                    )
                  }
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <span>
                      {t('table.number')}: {digitsEnToFa(item?.data?.table_id)}
                    </span>
                    <span>{jMoment(item?.created_at).fromNow()}</span>
                  </div>
                </Card>
              );

            default:
              return (
                <Card
                  hoverable
                  title={item.type}
                  headStyle={{
                    backgroundColor: item.read_at ? '#f0f2f5' : '#dcfce7',
                  }}
                  onClick={() => handleClick(item)}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <span>{item.title}</span>
                    <span>{jMoment(item?.created_at).fromNow()}</span>
                  </div>
                </Card>
              );
          }
        }}
      />
    </Drawer>
  );
}
