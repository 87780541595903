import { useEffect, useState, useCallback, useRef } from 'react';
import { BellOutlined } from '@ant-design/icons';
import NotificationDrawer from './notification-drawer';
import { Badge } from 'antd';
import pushNotification from 'services/pushNotification';
import { digitsEnToFa } from '@persian-tools/persian-tools';
import { Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import NotificationSound from '../assets/audio/notification.wav';

export default function NotificationBar() {
  const { t } = useTranslation();
  const [notificationDrawer, setNotificationDrawer] = useState(false);
  const [notifications, setNotifications] = useState();
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(
    Number.MAX_SAFE_INTEGER
  );
  const [showAll, setShowAll] = useState(false);
  const audioPlayer = useRef(null);

  const getNotifications = useCallback(async () => {
    try {
      const response = showAll
        ? await pushNotification.getAll()
        : await pushNotification.getUnread();

      setNotifications(response.data);
    } catch (error) {
      toast.error(t('error.occurred.try.again'));
    }
  }, [showAll, t]);

  function clearNotifications() {
    // delMany(notifications)
    //   .then(() => {
    //     setNotifications([]);
    //     setNotificationDrawer(false);
    //   })
    //   .catch(() => toast.error('Error occured'));
  }

  useEffect(() => {
    getNotifications();
    const interval = setInterval(getNotifications, 15000);
    return () => {
      clearInterval(interval);
    };
  }, [getNotifications]);

  /**
   * Notify on new notification item
   */
  useEffect(() => {
    if (!notifications) return;

    const unreadCount = notifications.filter((item) => !item.read_at).length;
    const newUnreadCount = unreadCount - unreadNotificationsCount;

    if (newUnreadCount > 0 && audioPlayer.current) {
      audioPlayer.current.play();
    }

    setUnreadNotificationsCount(unreadCount);
  }, [notifications, t, unreadNotificationsCount]);

  return (
    <>
      <audio
        id='notification-audio'
        ref={audioPlayer}
        src={NotificationSound}
      />
      <span className='icon-button' onClick={() => setNotificationDrawer(true)}>
        <Badge
          count={
            notifications?.length
              ? digitsEnToFa(unreadNotificationsCount)
              : undefined
          }
        >
          <BellOutlined style={{ fontSize: 20 }} />
        </Badge>
      </span>

      <NotificationDrawer
        visible={notificationDrawer}
        handleClose={() => setNotificationDrawer(false)}
        list={notifications || []}
        clear={clearNotifications}
        refetch={getNotifications}
        drawerExtra={
          <>
            {/* <Button onClick={() => clear()}>{t('clear')}</Button> */}
            <Radio.Group
              options={[
                { label: t('unread'), value: 'unread' },
                { label: t('all'), value: 'all' },
              ]}
              onChange={(e) => setShowAll(e.target.value === 'all')}
              value={showAll ? 'all' : 'unread'}
              optionType='button'
              buttonStyle='solid'
            />
          </>
        }
      />
      {/* <PushNotification refetch={getNotifications} /> */}
    </>
  );
}
